<template>
  <div id="employeeVacation" class="contenedor">
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-container fluid class="base text-center">
        <v-row
          no-gutters
          class="pa-0"
          style="height: auto !important; width: 100% !important"
          justify="center"
        >
          <v-col cols="12" sm="12" md="12" lg="12" align-self="center">
            <section class="bienvenida">
              <label
                >Te invitamos a terminar de contestar el cuestionario conforme a la NOM-035<br />
                Para continuar solo da clic en el botón Continuar.</label
              ><br />
                <v-img
                  src="../assets/quiz.jpg"
                  alt="Cuestionario Pendiente"
                  max-height="350"
                  max-width="400"
                  class="mx-auto"
                >
                </v-img
                ><br />
                <v-btn
                  color="orange accent-1"
                  x-large
                  height="40"
                  active-class="orange"
                  class="font-weight-black negro"
                  @click="validar()"
                  ><v-icon class="mr-1">fas fa-arrow-right</v-icon
                  >Continuar</v-btn
                >
            </section>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Sesion,
  },
  data() {
    return {
      expiration: false,
      cuestionario: localStorage.cuestionario,
    };
  },
  methods:{
validar(){

  var ruta = localStorage.cuestionario.indexOf(".com/");
  var datosRuta = localStorage.cuestionario.split("&");
  var guia = datosRuta[2].split("=");

    switch(guia[1])
      {
        case "1":
          this.$router.push("/guide");
          break;
        case "2":
          this.$router.push("/guideII");
          break;
        case "3":
          this.$router.push("/guideIII");
          break;
          default:  
            alert("No se encontro el guia ");
            break;
      }
  var posicionIdCuestionario = localStorage.cuestionario.indexOf(
        "?idCuestionario="
      );
}
  },
  created() {
  },
};
</script>